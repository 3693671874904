<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Exporter Tool
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="w-full"
      >
        <div class="flex flex-col space-y-4">
          <div class="bg-white shadow rounded-sm px-6 py-4">
            <p>You can export order data directly from the system for use elsewhere.</p>
            <p>The data will be provided in an .XLSX format and sent to your e-mail inbox.</p>
          </div>
          <div class="bg-white shadow rounded-sm px-6 py-4">
            <div class="flex flex-wrap w-row-offset-sm -mx-2">
              <div class="w-full lg:w-1/2 px-2">
                <vue-input-group
                  v-model="form.type"
                  :data="exportTypes"
                  prop="type"
                  label="Export Type"
                  description="Specify the type of data you are exporting."
                  labelKey="label"
                  valueKey="value"
                  type="radio-group"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end w-full space-x-4">
            <font-awesome-icon v-if="loading" :icon="['fas', 'spinner-third']" size="lg" fixed-width spin />
            <vue-button
              :disabled="!form.type || form.loading"
              type="submit"
              success
              @click.prevent="submitForm"
            >
              Export Data
            </vue-button>
          </div>
        </div>
      </vue-form>
      <vue-dialog :dialog.sync="dialog.show">
        <template #title>
          {{ dialog.title }}
        </template>
        {{ dialog.message }}
      </vue-dialog>
    </template>
  </NewModelLayout>
</template>
<script>
import NewModelLayout from '@/components/layouts/NewModelLayout'

export default {
  components: {
    NewModelLayout
  },

  data () {
    return {
      loading: false,
      error: [],
      form: {},
      exportTypes: [
        {
          label: 'Orders',
          value: 'orders'
        }
      ],
      dialog: {
        show: false,
        title: '',
        content: ''
      }
    }
  },

  methods: {
    submitForm () {
      this.loading = true
      if (this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('type', this.form.type)
        this.$api.post('api/orders/export', formData)
          .then((res) => {
            this.dialog = {
              show: true,
              title: 'Export successful! The exported data has been sent to your e-mail inbox.'
            }
          })
          .catch((err) => {
            this.$store.commit('error/addError', err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
